import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { useWith, identity, filter, path, includes, flip } from 'ramda'
// import { useAllCreditCard } from '@hooks/use-all-credit-card'
import flattenToBankIds from '@services/prismic/helpers/flatten-to-bank-ids'
// import parseCreditCard from '@services/prismic/helpers/parse-credit-card'
import ListByCard from './ListByCard'

const getBankId = useWith(path, [identity, identity])(['bank', 'prismicId'])
const banksIncludes = useWith(flip(includes), [identity, getBankId])


const CreditCardListByBoth = ({ cards, banks, max, onlyXsHeight, ...props }) => {
  const excludeBanks = cards.map((card) => {
    if(card.type === 'credit_card') {
      if(card.bank && card.bank.id)
        return card.bank.id
      if(card.bank && card.bank.prismicId)
        return card.bank.prismicId
    }
  })
  // const creditCardData = useAllCreditCard()
  const creditCards = useStoreState(({ allCreditCards }) => allCreditCards.list)
  const [filteredCreditCards, setFilteredCreditCards] = useState([])
  useEffect(() => {
    // const creditCards = parseCreditCard(creditCardData)
    const bankIds = flattenToBankIds(banks).filter(val => !excludeBanks.includes(val))
    setFilteredCreditCards([...cards, ...filter(banksIncludes(bankIds), creditCards)])
  }, [creditCards]);


  if (Array.isArray(filteredCreditCards) && filteredCreditCards.length > 0) { 
    return (
      <ListByCard cards={filteredCreditCards} max={max} onlyXsHeight={onlyXsHeight} {...props} />
    )
  }

  return null
}

export default CreditCardListByBoth
