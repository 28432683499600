/* eslint-disable camelcase */
import React, { useState } from 'react'
import Img from 'gatsby-image'
import { map, hasPath, join } from 'ramda'
import { useStoreActions } from 'easy-peasy'
import Box from '@lib/ui/core/Box'
import ButtonBase from '@lib/ui/core/ButtonBase'
import Typography from '@lib/ui/core/Typography'
import Hidden from '@lib/ui/core/Hidden'
// import CardListAllModal from './ListAllModal'
import CardListAllDrawer from './ListAllDrawer'

const renderImage = (card_image, onlyXsHeight) => {
  const isGatsbyImage = hasPath(['fixed'], card_image)
  const aspectRatio = hasPath(['dimensions'], card_image) ? card_image.dimensions.width / card_image.dimensions.height : 1
  const height = { xs: 25, sm: 35 }
  const width = { xs: Math.ceil(height.xs * aspectRatio), sm: Math.ceil(height.sm * aspectRatio) }

  const smParsedImage = {
    ...(
      isGatsbyImage ? card_image.fixed : {
        src: `${card_image.url}&h=${height.sm}&fit=max&q=50`,
        srcSet: join(', ', ['1', '1.5', '2'].map((dpr) => `${card_image.url}&&h=${height.sm}&fit=max&q=50&dpr=${dpr} ${dpr}x`)),
      }
    ),
    width: width.sm,
    height: height.sm,
  }

  const xsParsedImage = {
    ...(
      isGatsbyImage ? card_image.fixed : {
        src: `${card_image.url}&h=${height.xs}&fit=max&q=50`,
        srcSet: join(', ', ['1', '1.5', '2'].map((dpr) => `${card_image.url}&&h=${height.xs}&fit=max&q=50&dpr=${dpr} ${dpr}x,`)),
      }
    ),
    width: width.xs,
    height: height.xs,
  }

  return (
    <>
      <Hidden xsDown>
        <Img fixed={onlyXsHeight ? xsParsedImage : smParsedImage} alt={card_image.alt} imgStyle={{ objectFit: 'contain' }} />
      </Hidden>
      <Hidden smUp>
        <Img fixed={xsParsedImage} alt={card_image.alt} imgStyle={{ objectFit: 'contain' }} />
      </Hidden>
    </>
  )
}

const CreditCardListByCard = ({ cards, max = 3, onlyXsHeight = false, action }) => {
  const showMore = cards.length > max

  return (
    <Box display="flex" alignItems="center">
      { Array.isArray(cards) && cards.length > 0 && map(({ uid, card_image }) => ((
        hasPath(['fixed'], card_image) || hasPath(['fixedXs'], card_image) || hasPath(['url'], card_image))
        && (
        <Box key={uid} mr={1} fontSize={0} bgcolor="grey.100" style={{'cursor': 'default'}}>
          {renderImage(card_image, onlyXsHeight)}
        </Box>
        )), cards.slice(0, max)) }
      { showMore && (
        <Box mb={0.5}>
          <Typography variant="body1" onClick={action}>{cards.length - max}+</Typography>
        </Box>
      )}
    </Box>
  )
}

const CreditCardListByCardWithModal = ({ cards, max = 3, onlyXsHeight, ...rest }) => {
  const [open, setOpen] = useState(false)
  const [firstCardInfo, setFirstCardInfo] = useState([])
  // const reset = useStoreActions(({ offerSearch }) => offerSearch.reset)
  // const Cardchosen = useStoreState(({ offerSearch }) => offerSearch.cardChosen)
  const updateFirstCardInfo = useStoreActions(({ offerSearch }) => offerSearch.updateFirstCardInfo)
  const updateCardPayload = useStoreActions(({ offerSearch }) => offerSearch.updateCardPayload)
  const fetchSearchResult = useStoreActions(({ offerSearch }) => offerSearch.fetchSearchResult)
  const updateCardChosen = useStoreActions(({ offerSearch }) => offerSearch.updateCardChosen);
  const [chosenCard, setChosenCard] = useState([])


  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleFilter = () => {
    updateFirstCardInfo(firstCardInfo)
    updateCardPayload(Object.values(chosenCard).map((card) => card.prismicId))
    updateCardChosen(chosenCard)
    fetchSearchResult()
    setChosenCard(chosenCard)
    setFirstCardInfo(firstCardInfo)
  }
  // const showMore = cards.length > max

  // if (showMore) {
  //   return (
  //     <>
  //       <ButtonBase disableRipple onClick={handleClickOpen}>
  //         <CreditCardListByCard cards={cards} max={max} {...rest} />
  //       </ButtonBase>
  //       <CardListAllModal cards={cards} open={open} onClose={handleClose} />
  //     </>
  //   )
  // }

  // return (
  //   <CreditCardListByCard cards={cards} max={max} {...rest} />
  // )

  // allow to view card list as always
  return (
    <>
      <ButtonBase disableRipple data-ele-name="btn-credit-card-list" {...rest}>
        <CreditCardListByCard cards={cards} max={max} onlyXsHeight={onlyXsHeight} action={handleClickOpen} />
      </ButtonBase>
      <CardListAllDrawer firstCardInfo={firstCardInfo} setFirstCardInfo={setFirstCardInfo} chosenCard={chosenCard} setChosenCard={setChosenCard} open={open} setOpen={setOpen} handleClose={handleClose} cards={cards} handleFilter={handleFilter} />
    </>
  )
}

export default CreditCardListByCardWithModal
