/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, createContext } from "react"
import { useStoreActions, useStoreState } from "easy-peasy"
import styled from 'styled-components'
import useResponsive from "@hooks/use-responsive"
import Box from "@lib/ui/core/Box"
import Drawer from "@lib/ui/core/Drawer"
import Typography from "@lib/ui/core/Typography"
import Grid from "@lib/ui/core/Grid"
import Button from "@lib/ui/core/Button"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import store from "@store"
import Img from "gatsby-image"
import { hasPath, join } from "ramda"
import { ReactComponent as CloseIcon } from "@assets/icons/close-small.svg"
import Dialog from "@lib/ui/core/DialogWithCloseButton"
import Selected from "@components/Selected"
import { useTheme } from "@material-ui/core/styles"
import { OfferContextStore } from "@context"

const StyledTypography = styled(Typography)`
  text-decoration: underline;
  margin-right: 5px;
`

const renderImage = (card_image) => {
  const isGatsbyImage = hasPath(["fixed"], card_image)
  const aspectRatio = hasPath(["dimensions"], card_image)
    ? card_image.dimensions.width / card_image.dimensions.height
    : 1
  const height = 52
  const width = Math.ceil(height * aspectRatio)

  const parsedImage = {
    ...(isGatsbyImage
      ? card_image.fixed
      : {
        src: `${card_image.url}&h=${height}&fit=max&q=50`,
        srcSet: join(
          ", ",
          ["1", "1.5", "2"].map(
            (dpr) => `${card_image.url}&&h=${height}&fit=max&q=50&dpr=${dpr} ${dpr}x`
          )
        ),
      }),
    width,
    height,
  }


  return (
    <Box flexShrink={0} display="flex" justifyContent="center">
      <Box bgcolor="grey.100" clone>
        <Img
          fixed={parsedImage}
          alt={card_image.alt}
          imgStyle={{ objectFit: "contain" }}
        />
      </Box>
    </Box>
  )
}

const FilterByCard = ({ firstCardInfo, setFirstCardInfo, chosenCard, setChosenCard, cards, open, setOpen, handleClose, handleFilter }) => {
  const title = useContext(OfferContextStore)
  const fetchSearchResult = useStoreActions(
    ({ offerSearch }) => offerSearch.fetchSearchResult
  )
  const updateFirstCardInfo = useStoreActions(
    ({ offerSearch }) => offerSearch.updateFirstCardInfo
  )
  const updateCardPayload = useStoreActions(
    ({ offerSearch }) => offerSearch.updateCardPayload
  )

  const theme = useTheme()

  const handleClickCard = (clickedCard) => {
    const amendClickedCard = clickedCard
    amendClickedCard.bankId = clickedCard.bank.prismicId
    if (chosenCard.length === 0) {
      // chosenCard.push(clickedCard)
      setFirstCardInfo([...chosenCard])
      return setChosenCard([...chosenCard, amendClickedCard])
    }
    const existedIndex = chosenCard.findIndex(
      (card) => card.prismicId === amendClickedCard.prismicId
    )
    if (existedIndex > -1) {
      chosenCard.splice(existedIndex, 1)
      firstCardInfo.splice(existedIndex, 1)
    } else {
      chosenCard.push(amendClickedCard)
      firstCardInfo.push(amendClickedCard)
    }
    setChosenCard([...chosenCard])
    return setFirstCardInfo([...firstCardInfo])
  }


  const r = useResponsive()

  return (
    <Drawer width="100%" open={open}>
      <Layout headerTitle="適用信用卡" closeDrawer={handleClose}>
        <Container>
          <Box pt={{ xs: 4, sm: 7.5 }} mb={{ xs: 2.5, sm: 4 }} display="flex" justifyContent="center" alignItems="center">
            <Box display="inline" justifyContent="center" alignItems="center">
              <StyledTypography variant="h4" color="secondary" display="inline">
                {title}
              </StyledTypography>
              <Typography color="textPrimary" variant="body1" display="inline">適用於以下信用卡：</Typography>
            </Box>
          </Box>
          <Grid
            container
            alignItems="stretch"
            spacing={r({ xs: 1, sm: 2, md: 3 })}
          >
            {Array.isArray(cards)
              && cards.length > 0
              && cards.map((card) => (
                <Grid data-ele-name="eligible-credit-card-list-item" item md={4} sm={6} xs={12} key={card.uid}>
                  <Box data-ele-label={card.name}>
                    <Box
                      bgcolor="grey.100"
                      px={1.875}
                      py={1.75}
                      display="flex"
                      alignItems="center"
                      borderRadius="1.25rem"
                    >
                      <Box my="auto">
                        {chosenCard.some((chosen) => chosen.prismicId === card.prismicId) && (
                          <Box position="absolute">
                            <Box top="-12px" right="50%" position="relative"><Selected fontSize="1.5rem" /></Box>
                          </Box>
                        )}
                        {hasPath(["card_image"], card)
                          && renderImage(card.card_image)}
                      </Box>
                      {hasPath(["name"], card) && (
                        <Box px={2} flexShrink={1} flexGrow={1}>
                          <Typography variant="body1">{card.name}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Box
            position="sticky"
            display="flex"
            justifyContent="center"
            bottom={0}
            py={1}
            bgcolor="background.default"
          >
            <Button
              onClick={handleClose}
              fullWidth={r({ xs: true, sm: false })}
              px={r({ sm: 20.5 })}
            >
              關閉
            </Button>
          </Box>
        </Container>
      </Layout>
    </Drawer>
  )
}

export default FilterByCard
