import { map, useWith, ifElse, hasPath, path, identity } from 'ramda'

const flattenPathIfExist = (target, item) => ifElse(
  hasPath(target),
  path(target),
  identity
)(item)

const flattenListItemPathIfExist = (target, list) => {
  if (!(Array.isArray(list) && list.length > 0)) {
    return list
  }

  return map(
    useWith(flattenPathIfExist, [identity, identity])(target),
    list
  )
}

export default flattenListItemPathIfExist
