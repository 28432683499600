import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { useWith, identity, filter, path, includes, flip } from 'ramda'
// import { useAllCreditCard } from '@hooks/use-all-credit-card'
import flattenToBankIds from '@services/prismic/helpers/flatten-to-bank-ids'
// import parseCreditCard from '@services/prismic/helpers/parse-credit-card'
import ListByCard from './ListByCard'

const getBankId = useWith(path, [identity, identity])(['bank', 'prismicId'])
const banksIncludes = useWith(flip(includes), [identity, getBankId])


const CreditCardListByBank = ({ banks, max, onlyXsHeight, ...props }) => {
  // const creditCardData = useAllCreditCard()
  const creditCards = useStoreState(({ allCreditCards }) => allCreditCards.list)
  const [filteredCreditCards, setFilteredCreditCards] = useState([])
  const bankIds = flattenToBankIds(banks)
  const cardIds = filter(banksIncludes(bankIds), creditCards).filter((o)=>o.display_on_offer_page === true)
  useEffect(() => {
    setFilteredCreditCards(cardIds)
  }, [creditCards]);
  const t = []


  if (Array.isArray(filteredCreditCards) && filteredCreditCards.length > 0) {
    return (
      <ListByCard cards={filteredCreditCards} max={max} onlyXsHeight={onlyXsHeight} {...props} />
    )
  }

  return null
}

export default CreditCardListByBank
