import React from 'react'
import ListByBank from './ListByBank'
import ListByCard from './ListByCard'
import ListByBoth from './ListByBoth'
import ListAll from './ListAll'

const CreditCardList = ({ cards, banks, max, onlyXsHeight, ...props }) => {

  if (Array.isArray(cards) && cards.length > 0 && Array.isArray(banks) && banks.length > 0) {
    return (
      <ListByBoth cards={cards} banks={banks} max={max} onlyXsHeight={onlyXsHeight} {...props} />
    )
  }

  if (Array.isArray(cards) && cards.length > 0) {
    return (
      <ListByCard cards={cards} max={max} onlyXsHeight={onlyXsHeight} {...props} />
    )
  }

  if (Array.isArray(banks) && banks.length > 0) {
    return (
      <ListByBank banks={banks} max={max} onlyXsHeight={onlyXsHeight} {...props} />
    )
  }

  return (
      <ListAll max={max} onlyXsHeight={onlyXsHeight} {...props} />
    )
}

export default CreditCardList
