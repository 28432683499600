import React, { useEffect, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { useWith, identity, filter, path, includes, flip } from 'ramda'
// import { useAllCreditCard } from '@hooks/use-all-credit-card'
import flattenToBankIds from '@services/prismic/helpers/flatten-to-bank-ids'
// import parseCreditCard from '@services/prismic/helpers/parse-credit-card'
import ListByCard from './ListByCard'


const CreditCardListAll = ({ banks, max, onlyXsHeight, ...props }) => {
  // const creditCardData = useAllCreditCard()
  const creditCards = useStoreState(({ allCreditCards }) => allCreditCards.list)

  if (Array.isArray(creditCards) && creditCards.length > 0) {
    return (
      <ListByCard cards={creditCards} max={max} onlyXsHeight={onlyXsHeight} {...props} />
    )
  }

  return null
}

export default CreditCardListAll
